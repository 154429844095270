import { Injectable } from '@angular/core';
import { CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SecureGuard implements CanActivateChild {
  constructor(private afAuth: AngularFireAuth, private router: Router) {}

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.afAuth.user.pipe(
      map((user) => {
        return !!!user ? this.router.createUrlTree(['/u']) : true;
      })
    );
  }
}
