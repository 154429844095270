import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsecureGuard } from '../guards/unsecure.guard';
import { SecureGuard } from '../guards/secure.guard';

const routes: Routes = [
  // Unsecure Routes
  {
    path: 'u',
    canActivateChild: [UnsecureGuard],
    loadChildren: () =>
      import('./u/unsecure.module').then(m => m.CRUnsecureModule)
  },
  // Secure Routes
  {
    path: 's',
    canActivateChild: [SecureGuard],
    loadChildren: () => import('./s/secure.module').then(m => m.CRSecureModule)
  },
  // Common Routes
  {
    path: 'c',
    loadChildren: () => import('./c/common.module').then(m => m.CRCommonModule)
  },
  {
    path: '',
    redirectTo: 'u',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRouting {}
